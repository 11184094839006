import { Routes, Route } from 'react-router';

export function App() {
  const [counter, setCounter] = useState(1);
  return (
    <html>
      <head>
        <title>Server Rendered App</title>
      </head>
      <body>
        <Routes>
          <Route path="/" element={
            <div onClick={() => setCounter(counter + 1)}>{counter}</div>} />
          <Route path="/about" element={<div>About</div>} />
        </Routes>
        <script src="client.js" />
      </body>
    </html>
  );
}

export function Home() {
  const [counter, setCounter] = useState(1);
  return (
    <div onClick={() => setCounter(counter + 1)}>{counter}</div>
  )
}

function Base() {
  return (
    <html>
      <head>
        <title>Server Rendered App</title>
      </head>
      <body>
        <div id="root">
        <Home />
        </div>
        <script src="client.js" />
      </body>
    </html>
  );
}

import React, { useState } from 'react';
import ReactDOMServer from "react-dom/server";
import { StaticRouter } from "react-router-dom/server";

export async function handleRequest(request: Request): Promise<Response> {
  let html = ReactDOMServer.renderToString(
    <Base />
  );
  return new Response('<!DOCTYPE html>' + html, {
    headers: {
      "content-type": "text/html;charset=utf-8",
    },
  })
}
